<template>
  <div class="content page-box">
    <section class="content-box">
      <!-- 基本信息 -->
      <div class="detail-card">
        <header class="card-header">
          <h4 class="second-title">基本信息</h4>
        </header>
        <section class="card-content">
          <el-row
            :gutter="20"
            type="flex"
            align="normal"
          >
            <el-col :span="6">
              <div class="item">
                <label class="label">合同类型:</label>
                <p class="text">{{ conInfo.contractTypeValue }}</p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item">
                <label class="label">合同名称:</label>
                <p class="text">{{ conInfo.contractName }}</p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item">
                <label class="label">合同编号:</label>
                <p class="text">{{ conInfo.contractNo }}</p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item">
                <label class="label">经办人:</label>
                <p class="text">{{ conInfo.operatorName }}</p>
              </div>
            </el-col>
          </el-row>
          <el-row
            :gutter="20"
            type="flex"
            align="normal"
          >
            <!-- <el-col :span="6">
              <div class="item">
                <label class="label">经办部门:</label>
                <p class="text">{{ conInfo.contractDeptName }}</p>
              </div>
            </el-col> -->
            <el-col :span="6">
              <div class="item">
                <label class="label">经办日期:</label>
                <p class="text">{{ conInfo.contractDateStr }}</p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item">
                <label class="label">关联项目:</label>
                <p
                  class="text"
                  v-if="conInfo.projectGoalFlag === 'Y'"
                >{{ conInfo.projectName | addSerial }}</p>
                <p
                  class="text"
                  v-else
                >非项目目的</p>
              </div>
            </el-col>
            
            <el-col :span="6">
              <div class="item">
                <label class="label">是否需要付款:</label>
                <p class="text">
                  {{ conInfo.needPay == "Y" ? "需要" : "不需要" }}
                </p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item">
                <label class="label">是否供方合同:</label>
                <p class="text">
                  {{ conInfo.supplyStoreFlag == "0" ? "否" : "是" }}
                </p>
              </div>
            </el-col>
            
          </el-row>
          <el-row
            :gutter="10"
            type="flex"
            align="normal"
          >
            <el-col :span="6">
              <div class="item">
                <label class="label">形成方式:</label>
                <p class="text">{{ conInfo.forTypeValue | empty }}</p>
              </div>
            </el-col>
          
            <el-col :span="6"   v-if="conInfo.kgStage">
              <div class="item">
                <label class="label">所属阶段:</label>
                <p class="text">{{ conInfo.kgStage | kgStageFormatter}}</p>
              </div>
            </el-col>
            <el-col :span="6"   v-if="conInfo.isRelationFixPj == 1">
              <div class="item">
                <label class="label">具体合同类型:</label>
                <p class="text">{{ conInfo.fixContractType | fixContractType }}</p>
              </div>
            </el-col>
          </el-row>
          <el-row
            :gutter="20"
            type="flex"
            align="normal"
          >
            <!-- <el-col :span="6">
              <div class="item">
                <label class="label">关联解除合同:</label>
                <p class="text">{{ conInfo.forTypeValue | empty }}</p>
              </div>
            </el-col> -->
          </el-row>
        </section>
      </div>
      <!-- 签约主体 -->
      <div class="detail-card">
        <header class="card-header">
          <h4 class="second-title">签约主体</h4>
        </header>
        <section class="card-content">
          <div class="qy-body-flex-wrapper">
            <!-- 变更前主体 -->
            <div class="qy-body-item pink">
              <h2 class="qy-body-item-title">我方签约主体</h2>
              <ul class="qy-body-item-detail">
                <li class="qy-body-item-detail-li">
                  <ul>
                    <li v-if="!(conInfo.ourCompanyList && conInfo.ourCompanyList.length)">-</li>
                    <li v-for="(item, index) in conInfo.ourCompanyList" :key="index">{{item.name}}</li>
                  </ul>
                </li>
              </ul>
            </div>
            <!-- 变更后主体 -->
            <div class="qy-body-item pink">
              <h2 class="qy-body-item-title">对方签约主体</h2>
              <ul class="qy-body-item-detail">
                <li class="qy-body-item-detail-li">
                  <ul>
                    <li v-if="!(conInfo.supplierCompanyList && conInfo.supplierCompanyList.length)">-</li>
                    <li v-for="(item, index) in conInfo.supplierCompanyList" :key="index">
                      {{item.name}}
                  <span v-show="item.type === 'T04'" v-supplier>{{item.supplierSourceName || '供方'}}</span>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
      <!-- 招采信息 -->
      <div
        class="detail-card"
      >
        <header class="card-header">
          <h4 class="second-title">招采信息</h4>
        </header>
        <section class="card-content">
          <el-row
            :gutter="20"
            type="flex"
            align="normal"
          >
            <el-col :span="24">
              <div class="item">
                <label class="label">招标/比价/直委:</label>
                <p class="text">
                  {{ conInfo.orderMsg | empty }}
                </p>
              </div>
            </el-col>
          </el-row>
          <el-row
            :gutter="20"
            type="flex"
            align="normal"
            v-if="orderFile.length !== 0 "
          >
            <el-col :span="14">
              <div class="item">
                <label class="label">附件:</label>
                <div class="text">
                  <el-table
                    :show-header="false"
                    :data="orderFile"
                  >
                    <el-table-column
                      prop="fileName"
                      min-width="250"
                    >
                      <template slot-scope="scope">
                        <div>
                          <i class="el-icon-document"></i>
                          <a
                            @click="preview(scope.row, conInfo.id)"
                            class="link-name"
                          >{{ scope.row.fileName }}</a>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="createDate"
                      min-width="50"
                    >
                      <template slot-scope="scope">
                        <div class="light">
                          <span>{{ scope.row.bizCreateDateStr }}</span>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      align="center"
                      min-width="50"
                    >
                      <template slot-scope="scope">
                        <div class="orderDownload">
                          <span @click="download(scope.row)">下载</span>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </el-col>
          </el-row>
        </section>
      </div>
      <!-- 合同金额 -->
      <div
        class="detail-card"
      >
        <header class="card-header">
          <h4 class="second-title">合同金额</h4>
        </header>
        <section class="card-content">
          <el-row
            :gutter="20"
            type="flex"
            align="normal"
          >
            <el-col :span="6">
              <div class="item">
                <label class="label">币种:</label>
                <p class="text">{{ conInfo.currency | empty }}</p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item">
                <label class="label">合同金额(含税)(A):</label>
                <p class="text">{{ conInfo.contractAmount | millimeterFormat }}</p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item">
                <label class="label">不计成本金额(B):</label>
                <p class="text">{{ conInfo.costAmount | millimeterFormat | empty }}</p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item">
                <label class="label">有效签约金额(C=A-B):</label>
                <p class="text">
                  {{ conInfo.effContractAmount | millimeterFormat | empty }}
                </p>
              </div>
            </el-col>
          </el-row>
          <el-row
            :gutter="20"
            type="flex"
            align="normal"
          >
            <el-col :span="6">
              <div class="item">
                <label class="label">发票类型:</label>
                <p class="text">{{ conInfo.invoiceTypeValue | empty }}</p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item">
                <label class="label">可抵扣税额:</label>
                <p class="text">
                  {{ conInfo.deductibleTax | millimeterFormat | empty }}
                </p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item">
                <label class="label">可抵扣税率:</label>
                <p class="text">
                  {{ conInfo.deductibleTaxRate | formatRate }}
                </p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item">
                <label class="label">合同金额(不含抵扣税):</label>
                <p class="text">
                  {{ conInfo.exContractAmount | millimeterFormat | empty }}
                </p>
              </div>
            </el-col>
          </el-row>
          <el-row
            v-if="conInfo.aboveQuotaAmount || conInfo.aboveQuotaScale"
            :gutter="20"
            type="flex"
            align="normal"
          >
            <el-col :span="6">
              <div class="item">
                <label class="label">可超额付款比例/金额:</label>
                <p
                  class="text"
                  v-if="conInfo.aboveQuotaType == 'FIX'"
                >
                  {{ conInfo.aboveQuotaAmount | millimeterFormat | empty }}
                </p>
                <p
                  class="text"
                  v-else
                >
                  {{ conInfo.aboveQuotaScale | formatRate }}
                </p>
              </div>
            </el-col>
          </el-row>
        </section>
      </div>
      <!-- 费项分摊 -->
      <div
        class="detail-card"
        v-if="conInfo.needPay == 'Y' "
      >
        <header class="card-header">
          <h4 class="second-title">费项分摊</h4>
        </header>
        <section class="card-content">
          <el-row>
            <el-table
              :data="conInfo.costExpenseList"
              ref="costDate"
              style="width: 100%"
              border
              fit
              :header-row-style="{ height: '36px' }"
            >
              <el-table-column
                v-for="(item, index) in costColumn"
                :key="index"
                :label="item.label"
                :prop="item.prop"
                :align="item.align"
              ></el-table-column>
              <el-table-column
                align="right"
                label="分摊金额(含税)"
              >
                <template slot-scope="scope">
                  {{ scope.row.amount | millimeterFormat | empty }}
                </template>
              </el-table-column>
            </el-table>
          </el-row>
        </section>
      </div>
      <!-- 合同付款约定 -->
      <div
        class="detail-card"
        v-if="conInfo.needPay == 'Y' "
      >
        <header class="card-header">
          <h4 class="second-title">合同付款约定</h4>
        </header>
        <section class="card-content">
          <el-row>
            <el-col :span="24">
              <div class="item">
                <label class="label">说明信息:</label>
                <p class="text">
                  {{ conInfo.payAgreement | empty }}
                </p>
              </div>
            </el-col>
          </el-row>
        </section>
      </div>
      <!-- 合同说明 -->
      <div class="detail-card">
        <header class="card-header">
          <h4 class="second-title">合同说明</h4>
        </header>
        <section class="card-content">
          <el-row>
            <el-col :span="24">
              <div class="item">
                <label class="label">说明信息:</label>
                <p class="text">
                  {{ conInfo.contractNote | empty }}
                </p>
              </div>
            </el-col>
          </el-row>
        </section>
      </div>
      <!-- 附件 -->
      <div class="detail-card fileCard">
        <header class="card-header file-header">
          <h4 class="second-title">附件</h4>
          <button
            class="btn btn-lucency"
            @click="downloadAll"
            v-if="fileData.length !== 0"
          >
            下载全部附件
          </button>
        </header>
        <section class="card-content">
          <el-row>
            <el-table
              :data="fileData"
              ref="fileData"
              style="width: 100%"
              border
              fit
              :header-row-style="{ height: '36px' }"
            >
              <el-table-column
                label="附件"
                min-width="300"
                :show-overflow-tooltip="true"
              >
                <template slot-scope="scope">
                  <div class="box">
                    <i class="el-icon-document"></i>
                    <a
                      @click="preview(scope.row, conInfo.id)"
                      class="linkName"
                    >{{ scope.row.fileName }}</a>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                v-for="(item, index) in fileColumn"
                :key="index"
                :label="item.label"
                :align="item.align"
                :min-width="item.minWidth"
              >
              <template slot-scope="scope">
                <el-popover trigger="hover" placement="top" v-if="scope.row.fileNote && item.prop === 'fileNote' && scope.row.fileNote.length >= 66">
                  <p>{{ scope.row[item.prop] }}</p>
                  <div slot="reference" class="name-wrapper">
                    {{ scope.row[item.prop].slice(0, 66) }}{{ '...' }}
                  </div>
                </el-popover>
                <span v-else>{{ scope.row[item.prop] }}</span>
              </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="left"
                min-width="60"
              >
                <template slot-scope="scope">
                  <div class="fileDownload">
                    <span @click="download(scope.row)"> 下载 </span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-row>
        </section>
      </div>
    </section>
    <ImageViewer @closeViewer="closeViewer" v-if="showImageViewer" :urls="imageUrl"></ImageViewer>
  </div>
</template>
<script>
import axios from "axios";
import baseurl from "../api/baseUrl.js";
import preview from './mixins/preview'
import {fixContractType,kgStageFormatter} from '@/utils/Strmap'
export default {
  name: "businessInfo_Con",
  data() {
    return {
      conInfo: {}, // 合同信息展示
      // 招采附件列表
      orderFile: [],
      Token: "",
      // 表头列表
      costColumn: [
        {
          label: "责任人",
          prop: "operatorName",
        },
        {
          label: "费项",
          prop: "costItemsName",
        },
        {
          label: "预算分摊部门",
          prop: "costItemsDeptName",
        },
      ],
      // 附件列表
      fileData: [],
      // 附件表头
      fileColumn: [
        {
          label: "备注",
          prop: "fileNote",
          minWidth: 500,
        },
        {
          label: "上传时间",
          prop: "bizCreateDateStr",
          minWidth: 180,
        },
      ],
    };
  },
  mixins: [preview],
  filters:{
    fixContractType,
    kgStageFormatter
  },
  methods: {
    // 根据路由获取参数
    getUrl() {
      return new Promise((resolve) => {
        let params = this.$route.params;
        this.instanceId = params.id;
        this.Token = params.Token
        resolve(params);
      })
    },

    // 获取详细信息
    getConInfo(argument) {
      let baseUrl = baseurl + "/cms/inner"
      let processInstId = argument.id
      let Token = argument.Token
      this.Token = Token
      let params = {
        processInstId,
      }
      axios
        .post(
          baseUrl + "/contract/queryContractById",
          { params },
          {
            headers: {
              "Content-Type": "application/json",
              "Token": Token
            },
          }
        )
        .then((res) => {
          if (res.status !== 200) {
            return
          } 
          if (res.data.code !== "200") {
            if (res.data.code == "303" || res.data.code == "500") {
              this.$message({
                type: "error",
                message: "Token过期，请退出重新登陆",
              });
              return;
            }
            this.$message({
              type: "error",
              message: "找不到该合同信息！",
            });
            return;
          }

          let data = res.data.data;

          // 拆分招采附件和合同附件
          let fileList = data.attachFileList;
          this.orderFile = fileList.filter((item) => {
            return item.fileCategory == "RECRUIT";
          });

          this.fileData = fileList.filter((item) => {
            return item.fileCategory == "CONTRACT";
          });
          this.conInfo = data;
        });
    },
    // 下载附件
    download(row) {
      let baseUrl = baseurl + "/cms/inner";
      let Token = this.Token;
      let data = [{ "fileId": row.docLibId, "contractId": this.conInfo.id }]
      axios
        .post(
          baseUrl + "/file/fileDownload",
          { data },
          {
            headers: {
              "Content-Type": "application/json",
              "Token": Token
            },
          }
        )
        .then((res) => {
          if (res.status !== 200) return;
          if (res.data.code !== "200") {
            if (res.data.code == "303" || res.data.code == "500") {
              this.$message({
                type: "error",
                message: "Token过期，请退出重新登陆",
              });
              return;
            }
            this.$message({
              type: "error",
              message: "请求错误",
            });
            return;
          }
          const iframe = document.createElement("iframe");
          iframe.style.display = "none";
          iframe.style.height = 0;
          iframe.src = res.data.data
          document.body.appendChild(iframe);
          setTimeout(()=>{
            iframe.remove();
          }, 5 * 60 * 1000);
          })      
    },
    // 下载所有附件
    downloadAll() {
      this.fileData.forEach( item => {
        let data = [{ "fileId": item.docLibId, "contractId": this.conInfo.id }]
        let baseUrl = baseurl + "/cms/inner";
        let Token = this.Token;
        axios
          .post(
            baseUrl + "/file/fileDownload",
            { data },
            {
              headers: {
                "Content-Type": "application/json",
                "Token": Token
              },
            }
          )
          .then((res) => {
            if (res.status !== 200) return;
            if (res.data.code !== "200") {
              if (res.data.code == "303" || res.data.code == "500") {
                this.$message({
                  type: "error",
                  message: "Token过期，请退出重新登陆",
                });
                return;
              }
              this.$message({
                type: "error",
                message: "请求错误",
              });
              return;
            }
            const iframe = document.createElement("iframe");
            iframe.style.display = "none";
            iframe.style.height = 0;
            iframe.src = res.data.data
            document.body.appendChild(iframe);
            setTimeout(()=>{
              iframe.remove();
            }, 5 * 60 * 1000);
            }) 
      })
    },
  },
  created() {
    this.getUrl().then((res) => {
      this.getConInfo(res);
    })
  },
};
</script>

<style lang="less" scoped>
.page-box {
  .detail-card {
    .card-header {
      line-height: 36px;
    }

    .card-content {
      .el-row {
        margin-top: 12px;
      }

      .link-name {
        color: #997236;
        margin-left: 12px;

        &:hover {
          cursor: pointer;
        }
      }
      .box{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .orderDownload {
      border-left: 1px solid #e5e5e5;
      span {
        color: #997236;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
.linkName{
  margin-left: 10.5px;
}
</style>
<style lang="less">
.el-tooltip__popper{
  max-width: 100% !important;
  width: 300px;
}
</style>